
.column {
    &__question {
        width: 35%;
    }

    &__answer {
        width: 35%;
    }

    &__date {
        width: 10%;
    }

    &__host {
        width: 10%;
    }

    &__tools {
        width: 10%;
    }
}
