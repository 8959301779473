
.row-alike {
    display: flex;
}

.column-alike {
    padding: var(--n-th-padding);

    &.column__date,
    &.column__host,
    &.column__tools {
        // width: calc(10% - (var(--n-th-padding) / 2));
    }

    &.column__question,
    &.column__answer {
        width: calc(35%);
    }

    &:first-of-type {
    }
}

td {
    vertical-align: top;
}

.seamless-td {
    padding: 0 !important;
}

.details-link {
    display: block;
}

.no-border-bottom {
    border-bottom: none;
    padding-bottom: 0;
}

.sibling-bottom {
    padding-top: 0;
}

.score-details-table {
    margin-top: 10px;
}

.action-icon {
    cursor: pointer;
}

.pre-line {
    white-space: pre-line;
}
