
.hgroup {
    h2 {
        margin-bottom: 0;

        & + h3 {
            margin-top: 0;
            font-weight: normal;
        }
    }
}

table {
    th {
        vertical-align: top;
    }
}
