
.add-question-dialog {
    @media (max-width: 800px) {
        min-width: 100%;
    }
    @media (min-width: 801px) {
        min-width: 800px;
    }
}

.steps {
    border-right: 3px solid rgb(224, 224, 230);

    .n-step {
        padding-left: 10px;
    }
}

.select {
    &--large {
        .n-base-selection-label {
            font-size: 150%;
        }
    }
}

table {
    width: 100%;

    .name-td {
        width: 35%;
        max-width: 35%;
    }

    .point-td {
        width: 30%;
        max-width: 30%;
    }
}

.pre-line {
    white-space: pre-line;
}
