
#app {
    transition: all 0.5s;

    .loading {
        filter: blur(10px);
    }
}

.router-view {
    padding-left: 10px;
}

.team-avatar {
    span {
        transform: translateX(-50%) translateY(-50%) scale(1.5) !important;
    }
}
